<template>
    <section class="container" v-if="banner">
        <div class="sabc_tv_show_slider">
            <div class="swiper mySwiperBannerImage">
                <!-- The slideshow -->
                <div class="swiper-wrapper">
                    <div class="swiper-slide active" v-for="item in banner.bannerItems">
                        <a :href="item.image_link" :target="item.new_tab ? '_blank' : '_self'" aria-label="channel slider">
                            <!-- <img :src="$resizeImage(item.content, 1206, 678)" width="1206" height="678" alt="channel slider" loading="lazy"> -->
                            <picture>
                                <source media="(min-width: 991px)" :srcset="$resizeImage(item.content,  1206, 678)">
                                <source media="(min-width: 768px)" :srcset="$resizeImage(item.content, 522, 294)">
                                <source media="(min-width: 575px)" :srcset="$resizeImage(item.content, 414, 223)">
                                <img :src="$resizeImage(item.content, 342, 192)" width="1206" height="678" alt="channel slider" loading="lazy">
                            </picture>
                        </a>
                    </div>
                </div>
                <!-- Left and right controls -->
                <div id="swiper-button-prev" class="swiper-button-prev"></div>
                <div id="swiper-button-next" class="swiper-button-next"></div>
                <!-- <div class="swiper-pagination"></div> -->
            </div>
        </div>
    </section>
</template>
<script>
    import { Swiper, Navigation } from "swiper";
    Swiper.use([ Navigation ]);
    export default {
        name: "channel-slider",
        data() {
            return {
                matches: [],
            }
        },
        props: ['banner'],
        components: {},
        mounted() {
            new Swiper(".mySwiperBannerImage", {
                slidesPerView:1,
                observer: true,
                observeParents: true,
                paginationClickable: true,
                navigation: {
                    nextEl: "#swiper-button-next",
                    prevEl: "#swiper-button-prev",
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            });
        },
        serverPrefetch() {
        },
        methods: {
        },
    }
</script>
