<template>
    <div class="swiper " :class="(activeSport == sport)?swiperClass:' mySwiper2 '+' d-none' ">
        <div class="swiper-wrapper" >
            <section class="swiper-slide" v-for="match in matches" :key="match.id">
                <div class="sabc_livecenter_box">
                    <router-link :to="{ name: (sport=='soccer')?'match_detail.overview':'rugby_match_detail.overview', params: { slug: $slugify(match.tournament.name + ' ' + $getSafe(()=>match.competitors[0].team.name) + ' vs ' + $getSafe(()=>match.competitors[1].team.name)), date: getMatchDate(match.datetime), matchId: match.id } }">
                        <ul class="match-head-ul">
                            <li>
                                <!-- <strong class=" text-uppercase"> {{ (match.status=='NSY')?' Not Started ': (match.status == 'inprogress')?match.current_period:match.status}} </strong> -->
                                <span v-if="match.status == 'inprogress'" class="live">LIVE</span>
                                <strong class="sport-name"> {{sport[0].toUpperCase() + sport.slice(1)}} </strong>
                            </li>
                            <!-- <li>{{ match.tournament.footballCategory.name }} - {{ match.tournament.name }} </li> -->
                            <li> {{ match.tournament.name }} </li>
                        </ul>
                        <div class="matchcard-body">
                            <div class="playcard-col">
                                <div class="team-wrap" v-if="match.competitors[0].is_home">
                                    <div class="team-logo">
                                        <img loading="lazy" :onerror="$teamDefaultLogo()" :src="(sportLogoPath[sport] + match.competitors[0].team.id + '_90x90.webp')" :alt="match.competitors[0].team.name" width="53" height="36">
                                    </div>
                                    <span class="team-title">
                                        <span class="team-title-in">
                                            <span class="in-name ">{{ match.competitors[0].team.name }}</span>
                                        </span>
                                    </span>
                                </div>
                                <div class="team-wrap" v-else>
                                    <div class="team-logo">
                                        <img loading="lazy" :onerror="$teamDefaultLogo()" :src="(sportLogoPath[sport] + match.competitors[1].team.id + '_90x90.webp')" :alt="match.competitors[1].team.name" width="53" height="36">
                                    </div>
                                    <span class="team-title">
                                        <span class="team-title-in">
                                            <span class="in-name ">{{ match.competitors[1].team.name }}</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="playcard-col" v-if="match.status == 'inprogress'">
                                <div class="active team-wrap"><span class="competition-text">{{match.cfs}}</span></div>
                            </div>
                            <div class="playcard-col" v-else>
                                <div class="active team-wrap"><span class="competition-text">vs</span></div>
                            </div>
                            <div class="playcard-col">
                                <div class="team-wrap" v-if="!match.competitors[1].is_home">
                                    <div class="team-logo">
                                        <img loading="lazy" :onerror="$teamDefaultLogo()" :src="(sportLogoPath[sport] + match.competitors[1].team.id + '_90x90.webp')" :alt="match.competitors[1].team.name" width="53" height="36">
                                    </div>
                                    <span class="team-title">
                                        <span class="team-title-in">
                                            <span class="in-name ">{{ match.competitors[1].team.name }}</span>
                                        </span>
                                    </span>
                                </div>
                                 <div class="team-wrap" v-else>
                                    <div class="team-logo">
                                        <img loading="lazy" :onerror="$teamDefaultLogo()" :src="(sportLogoPath[sport] + match.competitors[0].team.id + '_90x90.webp')" :alt="match.competitors[0].team.name" width="53" height="36">
                                    </div>
                                    <span class="team-title">
                                        <span class="team-title-in">
                                            <span class="in-name ">{{ match.competitors[0].team.name }}</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- <p>{{ (match.status=='inprogress') ? match.current_period : getCurrentTimeZone(match.datetime) }}</p> -->
                        <!-- <p>{{ (match.status=='inprogress') ? match.current_period : match.datetime | matchSliderDateTime  }}</p> -->
                        <p v-if="match.status=='inprogress'">{{ match.current_period }}</p>
                        <p v-else>{{ match.datetime | matchSliderDateTime }}</p>
                    </router-link>
                </div>
            </section>
        </div>
        <div class="swiper-button-prev" :class="(activeSport == sport)?'':'d-none' "></div>
        <div class="swiper-button-next" :class="(activeSport == sport)?'':'d-none' "></div>
    </div>
</template>
<script>
    import moment from 'moment'
    export default {
        name: "sport-match-slider",
        data() {
            return {
                sportLogoPath : {
                    soccer : this.$storageURL+'images/football/',
                    cricket : this.$storageURL+'images/cricket/',
                    rugby : this.$storageURL+'images/rugby/',
                }
            }
        },
        props: ['sport', 'matches', 'activeSport', 'swiperClass'],
        components: {},
        mounted() {
        },
        serverPrefetch() {
        },
        methods: {
            getCurrentTimeZone(value) {
                return moment(String(value)).format('DD MMM HH:mm ')
            },
            getMatchDate(dateTime){
                return moment(String(dateTime)).format('YYYY-MM-DD')
            },
        }
    }
</script>
