<template>
    <section class="sabc_news_section sabc_news_nhtopstory">
        <div class="container">
            <div class="row">
              <!-- <div class="sabc_news_box_inr"> -->
                <div v-for="article in news" class="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div class="sabc_news_box">
                        <a :href="article.link" v-if="article.link.startsWith('http')" target="_blank" class="sabc_news_pic">
                            <!-- <img :src="$resizeImage(article.image.image_path, 295, 188)" :alt="article.headline" loading="lazy" height="295" width="188"/> -->
                            <picture>
                                <source media="(min-width: 991px)" :srcset="$resizeImage(article.image.image_path, 295, 188)">
                                <source media="(min-width: 768px)" :srcset="$resizeImage(article.image.image_path, 361, 188)">
                                <source media="(min-width: 575px)" :srcset="$resizeImage(article.image.image_path, 144, 91)">
                                <img :src="$resizeImage(article.image.image_path, 144, 91)" width="295" height="188" :alt="article.headline" :title="article.headline" loading="lazy">
                            </picture>
                            <label v-if="article.sport == null">News</label>
                            <label v-else-if="article.sport.name == 'Main Domain'">News</label>
                            <label v-else-if="article.sport.name != 'Main Domain' && type == 'all'">{{article.sport.name}}</label>
                        </a>
                        <router-link v-else :to="article.link" class="sabc_news_pic">
                            <!-- <img :src="$resizeImage(article.image.image_path, 295, 188)" :alt="article.headline" loading="lazy" height="295" width="188"/> -->
                            <picture>
                                <source media="(min-width: 991px)" :srcset="$resizeImage(article.image.image_path, 295, 188)">
                                <source media="(min-width: 768px)" :srcset="$resizeImage(article.image.image_path, 361, 188)">
                                <source media="(min-width: 575px)" :srcset="$resizeImage(article.image.image_path, 144, 91)">
                                <img :src="$resizeImage(article.image.image_path, 144, 91)" width="295" height="188" :alt="article.headline" :title="article.headline" loading="lazy">
                            </picture>
                            <label v-if="article.sport == null">News</label>
                            <label v-else-if="article.sport.name == 'Main Domain'">News</label>
                            <label v-else-if="article.sport.name != 'Main Domain' && type == 'all'">{{article.sport.name}}</label>
                        </router-link>
                        <div class="sabc_news_desc">
                            <h3>
                                <a :href="article.link" v-if="article.link.startsWith('http')" target="_blank">{{article.headline}}</a>
                                <router-link v-else :to="article.link">{{article.headline}}</router-link>
                                <label v-if="article.sport == null">News</label>
                                <label v-else-if="article.sport.name == 'Main Domain'">News</label>
                                <label v-else-if="article.sport.name != 'Main Domain' && type == 'all'">{{article.sport.name}}</label>
                            </h3>
                        </div>
                    </div>
                </div>
              <!-- </div> -->
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'latest-news-row',
        jsonld() {
            if (isServer)
                return {
                    "@context": "http://schema.org",
                    "@graph": []
                }

            if (!this.news)
                return

            let events = [];
            this.news.forEach((article) => {
                events.push(
                    {
                        '@type': 'NewsArticle',
                        mainEntityOfPage: {
                            '@type': 'WebPage',
                            '@id': article.link
                        },
                        headline:  article.headline,
                        description: article.blurb,
                        image:  article.image.image_path,
                        author: {
                            '@type': 'Person',
                            name: "SABC",
                            "url" : this.$siteURL
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: "SABC",
                            logo: {
                                '@type': 'ImageObject',
                                url: this.$siteURL + 'public/images/logo.png'
                            }
                        },
                        datePublished: article.created_at,
                        dateModified: ''
                    }
                )
            });
            return {
                "@context": "http://schema.org",
                "@graph": events
            };
        },
        props: ['news','type'],
        data() {
            return {
            }
        },

        mounted() {
        },

        methods: {}
    }
</script>
