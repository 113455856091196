<template>
    <section class="sabc_featured_area">
        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                    <div class="sabc_main_featured">
                        <a :href="topStories[0].link" v-if="topStories[0].link.startsWith('http')" target="_blank">
                            <link rel="preload" :href="$resizeImage(topStories[0].image.image_path,320, 267)" as="image"/>
                            <link rel="preload" :href="$resizeImage(topStories[0].image.image_path,798,554)" as="image"/>
                            <!-- <img :src="$resizeImage(topStories[0].image.image_path,796,500)" width="814" height="500" :alt="(topStories[0].image.caption)?topStories[0].image.caption:topStories[0].image.file_name"> -->
                            <picture>
                                <source media="(min-width: 991px)" :srcset="$resizeImage(topStories[0].image.image_path, 798, 554)">
                                <source media="(min-width: 768px)" :srcset="$resizeImage(topStories[0].image.image_path, 740, 267)">
                                <source media="(min-width: 575px)" :srcset="$resizeImage(topStories[0].image.image_path, 575, 267)">
                                <img :src="$resizeImage(topStories[0].image.image_path, 320, 267)" width="814" height="500" :alt="(topStories[0].image.caption)?topStories[0].image.caption:topStories[0].image.file_name" :title="(topStories[0].image.caption)?topStories[0].image.caption:topStories[0].image.file_name">
                            </picture>
                            <div class="sabc_main_featured_dtl">
                                <!--                                <span class="sabc_main_featured_playicon"></span>-->
                                <div class="sabc_main_featured_desc">
                                    <label v-if="topStories[0].is_live">WATCH LIVE</label>
                                    <label class="ts-main-label" v-else>Top Stories</label>
                                    <h2>
                                        <span v-if="topStories[0].is_live">
                                           <img :src="require('@/assets/images/sabc_live_icon.webp')" alt="sabc_live_icon"/>
                                           LIVE
                                        </span>
                                        {{topStories[0].headline}}
                                    </h2>
                                </div>
                            </div>
                        </a>
                        <router-link  :to="topStories[0].link" v-else>
                            <link rel="preload" :href="$resizeImage(topStories[0].image.image_path,320, 267)" as="image"/>
                            <link rel="preload" :href="$resizeImage(topStories[0].image.image_path,798,554)" as="image"/>
                            <!-- <img :src="$resizeImage(topStories[0].image.image_path,796,500)" width="814" height="500" :alt="(topStories[0].image.caption)?topStories[0].image.caption:topStories[0].image.file_name"> -->
                            <picture>
                                <source media="(min-width: 991px)" :srcset="$resizeImage(topStories[0].image.image_path, 798, 554)">
                                <source media="(min-width: 768px)" :srcset="$resizeImage(topStories[0].image.image_path, 740, 267)">
                                <source media="(min-width: 575px)" :srcset="$resizeImage(topStories[0].image.image_path, 575, 267)">
                                <img :src="$resizeImage(topStories[0].image.image_path, 320, 267)" width="814" height="500" :alt="(topStories[0].image.caption)?topStories[0].image.caption:topStories[0].image.file_name" :title="(topStories[0].image.caption)?topStories[0].image.caption:topStories[0].image.file_name">
                            </picture>
                            <div class="sabc_main_featured_dtl">
<!--                                <span class="sabc_main_featured_playicon"></span>-->
                                <div class="sabc_main_featured_desc">
                                    <label v-if="topStories[0].is_live">WATCH LIVE</label>
                                    <label class="ts-main-label" v-else>Top Stories</label>
                                    <h2>
                                        <span v-if="topStories[0].is_live">
                                           <img :src="require('@/assets/images/sabc_live_icon.webp')" alt="sabc_live_icon"/>
                                           LIVE
                                        </span>
                                    {{topStories[0].headline}}
                                    </h2>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <div class="sabc_sub_featured" v-for="item in topStories.slice(1)">
                        <a :href="item.link" v-if="item.link.startsWith('http')" target="_blank">
                            <!-- <img :src="$resizeImage(item.image.image_path,400,248)" width="405" height="248" :alt="(item.image.caption)?item.image.caption:item.image.file_name"> -->
                            <picture>
                                <source media="(min-width: 991px)" :srcset="$resizeImage(item.image.image_path, 400, 248)">
                                <source media="(min-width: 768px)" :srcset="$resizeImage(item.image.image_path, 359, 248)">
                                <source media="(min-width: 575px)" :srcset="$resizeImage(item.image.image_path, 270, 114)">
                                <img :src="$resizeImage(item.image.image_path, 147, 114)" width="405" height="248" :alt="(item.image.caption)?item.image.caption:item.image.file_name" :title="(item.image.caption)?item.image.caption:item.image.file_name">
                            </picture>
                            <div class="sabc_sub_featured_dtl">
                                <div class="sabc_sub_featured_desc">
                                    <label>Top Stories</label>
                                    <h2>
                                        {{item.headline}}
                                    </h2>
                                </div>
                            </div>
                        </a>
                        <router-link :to="item.link" v-else>
                            <!-- <img :src="$resizeImage(item.image.image_path,400,248)" width="405" height="248" :alt="(item.image.caption)?item.image.caption:item.image.file_name"/> -->
                            <picture>
                                <source media="(min-width: 991px)" :srcset="$resizeImage(item.image.image_path, 400, 248)">
                                <source media="(min-width: 768px)" :srcset="$resizeImage(item.image.image_path, 359, 248)">
                                <source media="(min-width: 575px)" :srcset="$resizeImage(item.image.image_path, 270, 114)">
                                <img :src="$resizeImage(item.image.image_path, 147, 114)" width="405" height="248" :alt="(item.image.caption)?item.image.caption:item.image.file_name" :title="(item.image.caption)?item.image.caption:item.image.file_name">
                            </picture>
                            <div class="sabc_sub_featured_dtl">
                                <div class="sabc_sub_featured_desc">
                                    <label>Top Stories</label>
                                    <h2>
                                        {{item.headline}}
                                    </h2>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'TopStories',
        jsonld() {
            if (isServer)
                return {
                    "@context": "http://schema.org",
                    "@graph": []
                }

            if (!this.topStories)
                return

            let events = [];
            this.topStories.forEach((article) => {
                events.push(
                    {
                        '@type': 'NewsArticle',
                        mainEntityOfPage: {
                            '@type': 'WebPage',
                            '@id': article.link
                        },
                        headline:  article.headline,
                        description: article.blurb,
                        image:  article.image.image_path,
                        author: {
                            '@type': 'Person',
                            name: "SABC",
                            "url" : this.$siteURL
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: "SABC",
                            logo: {
                                '@type': 'ImageObject',
                                url: this.$siteURL + 'public/images/logo.png'
                            }
                        },
                        datePublished: article.created_at,
                        dateModified: ''
                    }
                )
            });
            return {
                "@context": "http://schema.org",
                "@graph": events
            };
        },
        props: ['topStories'],
        data() {
            return {
            }
        },

        mounted() {
        },

        methods: {}
    }
</script>
